<template>
    <div>
      <b-row>
        
        <b-col md="6" v-for="(settingType, index) in payoutTypes" :key="index" class="my-2" style="text-align: center;">
  
          <b-card class="h-100 ">
            <statistic-card-vertical
              icon="SettingsIcon"
              :statistic="settingType.replace(/([A-Z,'_'])/g, ' ').trim().toUpperCase() "
              statistic-title=""
              color="success"
            />
            <b-button
              variant="success"
             
              :to="{ path: '/payout/add/' + settingType}"
              >Show</b-button
            >
          </b-card>
  
          
        </b-col>
      </b-row>
  
    
    </div>
  </template>
    
    <script>
    
  import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";
  
  import { heightTransition } from '@core/mixins/ui/transition'
  
  export default {
    components: {
   
      StatisticCardVertical,
    },
  
    mixins: [heightTransition],
    data() {
      return {
        payoutTypes: ['vendors', 'users'],

      }
    },
   
    watch: {},
   
   
    methods: {},
  }
  </script>
    
    <style scoped lang="scss">
  </style>
    